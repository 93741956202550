import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const List = makeShortcode("List");
const Link = makeShortcode("Link");
const Box = makeShortcode("Box");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "crafted-reading---patterns-and-trust",
      "style": {
        "position": "relative"
      }
    }}>{`Crafted Reading - Patterns and Trust`}<a parentName="h1" {...{
        "href": "#crafted-reading---patterns-and-trust",
        "aria-label": "crafted reading   patterns and trust permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <List mdxType="List">
      <Link to="https://www.gdcvault.com/play/1023329/Engines-of-Play-How-Player" mdxType="Link">
        <h4 {...{
          "id": "engines-of-play-how-player-motivation-changes-over-time",
          "style": {
            "position": "relative"
          }
        }}>{`Engines of Play: How Player Motivation Changes Over Time`}<a parentName="h4" {...{
            "href": "#engines-of-play-how-player-motivation-changes-over-time",
            "aria-label": "engines of play how player motivation changes over time permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h4>
        <p>{`This GDC talk by `}<Link to="https://twitter.com/the_darklorde" mdxType="Link">{`Jason VandenBerghe`}</Link>{`, designer of Ubisoft's `}<em parentName="p">{`For Honor`}</em>{`, is a great primer on how psychology affects game design. `}</p>
        <p>{`It's an engaging watch and a dense presentation. One powerful takeaway is that the reasons we start playing a game and the reasons we continue playing have to do with very different personal motivations.`}</p>
      </Link>
      <Link to="https://www.gamasutra.com/view/feature/129948/the_chemistry_of_game_design.php?page=1" mdxType="Link">
        <h4 {...{
          "id": "the-chemistry-of-game-design",
          "style": {
            "position": "relative"
          }
        }}>{`The Chemistry of Game Design`}<a parentName="h4" {...{
            "href": "#the-chemistry-of-game-design",
            "aria-label": "the chemistry of game design permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h4>
        <p>{`Written by `}<Link to="https://twitter.com/danctheduck" mdxType="Link">{`Daniel Cook`}</Link>{` about a decade before Jason's `}<em parentName="p">{`Engines of Play`}</em>{`. It's an earlier look into how game designers were thinking about games in a time before app stores and the current attention economy. `}</p>
        <p>{`Daniel's work has been massively influential on game design, surfacing primitives (such as game loops) and pioneering today's successful mechanics (such as Merge-3).`}</p>
      </Link>
      <Link to="https://tonysheng.substack.com/" mdxType="Link">
        <h4 {...{
          "id": "stuffed-blocks",
          "style": {
            "position": "relative"
          }
        }}>{`Stuffed Blocks`}<a parentName="h4" {...{
            "href": "#stuffed-blocks",
            "aria-label": "stuffed blocks permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h4>
        <p>{`A blog by Tony Sheng, and a treasure trove of critical thinking on game ecosystems. Some great posts: `}</p>
        <Box mdxType="Box">
          <ul>
            <li parentName="ul">
              <Link to="https://tonysheng.substack.com/p/game-economies" mdxType="Link">Do Open In-Game Economies Make Games Less Fun</Link>
            </li>
            <li parentName="ul">
              <Link to="https://tonysheng.substack.com/p/vitaliks-warlock-probably-deserved" mdxType="Link">Vitalik's Warlock Probably Deserved It</Link>
            </li>
          </ul>
        </Box>
      </Link>
    </List>
    <h2 {...{
      "id": "web-3-game-counterparts",
      "style": {
        "position": "relative"
      }
    }}>{`Web 3 Game Counterparts`}<a parentName="h2" {...{
        "href": "#web-3-game-counterparts",
        "aria-label": "web 3 game counterparts permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Most Web 3 Games take inspiration from other games and media:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Web 3 game`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Similar to`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`As well as`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`And`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Cryptokitties`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Pokemon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Baseball Trading Cards`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Axie Infinity`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Decentraland`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Cryptovoxels`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Second Life`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The Sims`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sandbox`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Roblox`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Minecraft`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`LEGO`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Skyweaver`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Hearthstone`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Legends of Runeterra`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Magic: The Gathering`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`What makes each of these games different? Do they have the same audiences? How can they become more successful than their competitor?`}</p>
    <p>{`Are there any others? Do add more with a PR! 📝`}</p>
    <List mdxType="List">
      <Link to="https://steamcommunity.com/profiles/76561198024087514/recommended/8500/" mdxType="Link">
        <h4 {...{
          "id": "ttvdrandoks-review-of-eve-online",
          "style": {
            "position": "relative"
          }
        }}>{`TTV/Drandok's Review of Eve Online`}<a parentName="h4" {...{
            "href": "#ttvdrandoks-review-of-eve-online",
            "aria-label": "ttvdrandoks review of eve online permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h4>
        <p>{`A great real-world example of the oft-quoted truism, `}<strong parentName="p">{`"What the game is, defines what the players do."`}</strong>{` A player eloquently lays out why the game isn't really 'free', and how freedom of choice in the game is an illusion. Here's a poignant quote: `}</p>
        <blockquote>
          <p parentName="blockquote"><strong parentName="p">{`"I just don't feel that this is a 'game' anymore than it is a river, and newcomers are raindrops. Farewell."`}</strong></p>
        </blockquote>
      </Link>
    </List>
    <h2 {...{
      "id": "breakout-activity",
      "style": {
        "position": "relative"
      }
    }}>{`Breakout Activity`}<a parentName="h2" {...{
        "href": "#breakout-activity",
        "aria-label": "breakout activity permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`For each table, each fellow should introduce the Web 3 projects they're conceptualizing / working on. Each other member will then provide feedback, focused on answering these questions: `}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Identifying Patterns`}</strong>{`: Are there other apps / games / media that remind you of your fellow's project? What makes them different? Similar? What are some things that these other projects are doing correctly which your fellow's project should take inspiration from? Note that your suggested similar projects don't have to be built on Web 3, it might be even more helpful to share projects from other platforms instead.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Trust`}</strong>{`: Do you see any part of your fellows' project that can be tweaked in order to build more trust among users? Alternatively, is there anything in their project that stands out to you as a possible opportunity for someone to exploit? If there is, suggest some ways on how to close that exploit.`}</p>
      </li>
    </ol>
    <p>{`If the group finds it helpful, someone could use collaborative editing software - such as Miro, Figma or Google Drawings - to facilitate discussions. Please share these on Slack. If you do, your projects might get more helpful feedback!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      